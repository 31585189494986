/* eslint-disable no-underscore-dangle */
import Fuse from 'fuse.js';
import {
  AdditionalImageFragment,
  BasicProductFragment,
  ImageFragment,
  PrimaryProductFragment,
  ProductFragment,
  SizeFragment,
} from '@/gql';
import { isNotNullish, isNullish } from '@/lib/utils';
import { ColorOption } from '@/types/color-option';
import { SizeOption, SizeOptions } from '@/types/size-option';
import { DIGITAL_ITEM_SIZE_LABEL } from '@/constants';
import { Sku, SkuWithQuantity } from '@/types/product';

/**
 * Type guard for Sku
 * @param candidate
 * @returns
 */
export function isSku(candidate: any): candidate is Sku {
  return (
    isNotNullish(candidate) &&
    typeof candidate === 'object' &&
    typeof candidate.campaignRootId === 'number' &&
    Number.isInteger(candidate.campaignRootId) &&
    typeof candidate.productId === 'number' &&
    Number.isInteger(candidate.productId) &&
    typeof candidate.variationId === 'number' &&
    Number.isInteger(candidate.variationId) &&
    typeof candidate.sizeId === 'number' &&
    Number.isInteger(candidate.sizeId)
  );
}

/**
 * Type guard for SkuWithQuantity
 * @param candidate
 * @returns
 */
export function isSkuWithQuantity(candidate: any): candidate is SkuWithQuantity {
  return (
    typeof candidate === 'object' &&
    typeof candidate.quantity === 'number' &&
    Number.isInteger(candidate.quantity) &&
    isSku(candidate)
  );
}

/**
 * Try to find a listing product in an array of product/listing items
 *
 * @param products The products to search in
 * @param listingSlug The listing slug for the product to search for  (optional, but only for convenience, a valid listing id is required to succeed)
 * @param productId The id of the product to search for  (optional, but only for convenience, a valid product id is required to succeed)
 * @param defaultValue  Default value (optional)
 * @returns The matching ProductFragment, the default, or undefined
 */
export function findListingProduct(
  products: ProductFragment[] | undefined,
  listingSlug?: string,
  productId?: number,
  defaultValue?: ProductFragment
): ProductFragment | undefined {
  return (
    (isNotNullish(products) &&
      isNotNullish(listingSlug) &&
      isNotNullish(productId) &&
      products.find((listing) => listing?.url === listingSlug && listing?.primaryProductId === productId)) ||
    defaultValue
  );
}

/**
 * Try to find a product/listing variation with a given variation (aka color) id
 *
 * @param product The product (aka listing) to search in
 * @param variationId  The target variation id (optional, but only for convenience, a valid variation id is required to succeed)
 * @param defaultValue Default value (optional)
 * @returns The matching PrimaryProductFragment, the default, or undefined
 */
export function findVariant(
  product: ProductFragment | undefined,
  variationId?: number,
  defaultValue?: PrimaryProductFragment
): PrimaryProductFragment | undefined {
  return (
    (isNotNullish(variationId) && product?.primaryProduct?.find((v) => v?.variationId === variationId)) || defaultValue
  );
}

/**
 * Try to find product variation size with a given size id
 *
 * @param variant The variant to look in
 * @param sizeId The target size id (optional, but only for convenience, a valid size id is required to succeed)
 * @param defaultValue Default value (optional)
 * @returns The matching size, the default, or undefined
 */
export function findSize(
  variant: PrimaryProductFragment | undefined,
  sizeId?: number,
  defaultValue?: SizeFragment
): SizeFragment | undefined {
  return variant?.sizes?.find((s) => s?.id === sizeId) || defaultValue;
}

/**
 * Try to find variation and size, by ids
 *
 * @param listingProduct The products to search in (optional, but only for convenience, a valid listing id is required to succeed)
 * @param variationId  The target variation id (optional, but only for convenience, a valid variation id is required to succeed)
 * @param sizeId The target size id (optional, but only for convenience, a valid size id is required to succeed)
 * @returns Object with the listing product, variation, and size if found, undefined on no match
 */
export function findListingProductVariantSize(
  listingProduct: ProductFragment | undefined,
  variationId?: number,
  sizeId?: number
):
  | {
      variant: PrimaryProductFragment;
      size: SizeFragment;
    }
  | undefined {
  const variant = findVariant(listingProduct, variationId);
  const size = findSize(variant, sizeId);

  if (variant && size) {
    return {
      variant,
      size,
    };
  }

  return undefined;
}

/**
 * Try to find listing product, variation, and size by ids
 *
 * @param listingProducts The products to search in
 * @param listingSlug The listing slug for the product to search for  (optional, but only for convenience, a valid listing id is required to succeed)
 * @param productId The id of the product to search for  (optional, but only for convenience, a valid product id is required to succeed)
 * @param variationId  The target variation id (optional, but only for convenience, a valid variation id is required to succeed)
 * @param sizeId The target size id (optional, but only for convenience, a valid size id is required to succeed)
 * @returns Object with the listing product, variation, and size if found, undefined on no match
 */
export function findListingProductsVariantSize(
  listingProducts: ProductFragment[] | undefined,
  listingSlug?: string,
  productId?: number,
  variationId?: number,
  sizeId?: number
):
  | {
      listingProduct: ProductFragment;
      variant: PrimaryProductFragment;
      size: SizeFragment;
    }
  | undefined {
  const listingProduct = findListingProduct(listingProducts, listingSlug, productId);
  const { variant, size } = findListingProductVariantSize(listingProduct, variationId, sizeId) || {};

  if (listingProduct && variant && size) {
    return {
      listingProduct,
      variant,
      size,
    };
  }

  return undefined;
}

/**
 * Select a default variant from a given listing (which is unfortunately of type Product)
 *
 * @param product The listing product
 * @param variationId The prefered variation
 * @returns The settled upon variant, or undefined
 */
export function selectProductVariant(
  product?: ProductFragment,
  variationId?: number
): PrimaryProductFragment | undefined {
  // wait for current product data
  if (!product) {
    return undefined;
  }

  let candidateVariant: PrimaryProductFragment | undefined;

  // is a variation id specified?
  if (!isNullish(variationId)) {
    candidateVariant = findVariant(product, variationId);
  }

  // fall back to first with available size(s)
  if (!candidateVariant) {
    candidateVariant = product?.primaryProduct?.find((v) => v.availableSizes && v.availableSizes.length > 0);
  }

  // fall back to first
  if (!candidateVariant) {
    candidateVariant = product?.primaryProduct?.[0] || undefined;
  }

  // adopt the candidate?
  return candidateVariant ?? undefined;
}

/**
 * Select a default variant size from a given variation
 *
 * @param variant The listing product variant to pick a size from
 * @param variationId The prefered variation
 * @returns The settled upon size, or undefined
 */
export function selectVariantSize(variant?: PrimaryProductFragment, sizeId?: number): SizeFragment | undefined {
  // ignore size unless we have a variant
  if (!variant) {
    return undefined;
  }

  let candidateSize: SizeFragment | undefined;

  // was a size id specified?
  if (!isNullish(sizeId)) {
    candidateSize = findSize(variant, sizeId);
  }

  // fallback to first available size
  if (!candidateSize) {
    candidateSize = variant.availableSizes?.[0];
  }

  // fallback to first size
  if (!candidateSize) {
    candidateSize = variant.sizes?.[0];
  }

  // adopt the candidate?
  return candidateSize ?? undefined;
}

/**
 * Build a sku string from the components
 *
 * @param itemGroupId
 * @param variationId
 * @param sizeId
 * @returns string sku (with quantity) */
export function buildSku(itemGroupId: string, variationId: number, sizeId: number) {
  return `${itemGroupId}_${variationId}_${sizeId}`;
}

/**
 * Build a sku string (with quantity) from the components
 *
 * @param itemGroupId
 * @param variationId
 * @param sizeId
 * @param quantity
 * @returns string sku (with quantity)
 */
export function buildSkuWithQuantity(itemGroupId: string, variationId: number, sizeId: number, quantity: number) {
  return `${buildSku(itemGroupId, variationId, sizeId)}_${quantity}`;
}

/**
 * Parse a product sku into a typed Sku object
 *
 * @param skuString
 * @returns Sku object, or undefined
 */
export function parseSku(skuString: string): Sku | undefined {
  let sku: Sku | undefined;
  const skuBits = skuString ? skuString.split('_') : [];
  if (skuBits.length >= 4) {
    sku = {
      campaignRootId: parseInt(skuBits[0], 10),
      productId: parseInt(skuBits[1], 10),
      variationId: parseInt(skuBits[2], 10),
      sizeId: parseInt(skuBits[3], 10),
    };
  }
  return sku && isSku(sku) ? sku : undefined;
}

/**
 * Parse a product sku string (with quantity) into a typed Sku object
 *
 * @param skuString
 * @returns Sku object, or undefined
 */
export function parseSkuWithQuantity(skuString: string): SkuWithQuantity | undefined {
  let skuWithQuantity: SkuWithQuantity | undefined;
  const skuBits = skuString ? skuString.split('_') : [];
  if (skuBits.length >= 5) {
    skuWithQuantity = {
      campaignRootId: parseInt(skuBits[0], 10),
      productId: parseInt(skuBits[1], 10),
      variationId: parseInt(skuBits[2], 10),
      sizeId: parseInt(skuBits[3], 10),
      quantity: parseInt(skuBits[4], 10),
    };
  }
  return skuWithQuantity && isSkuWithQuantity(skuWithQuantity) ? skuWithQuantity : undefined;
}

/**
 * Historically we've run across some listings with null products in them, this
 * helper makes sure a product fragment always has a primaryProduct field (possibly
 * any empty array) that contains no falsy entries
 *
 * @param product
 * @returns
 */
export function normalizePrimaryProducts(product: ProductFragment): ProductFragment {
  // Remove nulls, setting an empty array if none was on the input
  const filtered = product.primaryProduct?.filter((prod) => prod) || [];

  // If nothing changed, just return the input
  if (isNotNullish(product.primaryProduct) && product.primaryProduct.length === filtered.length) {
    return product;
  }

  // Clone the product, swapping in the filtered product array (aka variants/colors)
  return {
    ...product,
    primaryProduct: filtered,
  };
}

/**
 * Basic product search
 *
 * @param products The set of products to search in
 * @param query  The search string
 * @returns Array of matching products
 */
export function searchBasicProducts(products: readonly BasicProductFragment[], query: string): BasicProductFragment[] {
  const normalizedQuery = query.trim();

  const options: Fuse.IFuseOptions<BasicProductFragment> = {
    isCaseSensitive: false,
    shouldSort: true,
    includeScore: false,
    minMatchCharLength: 1,
    keys: [
      {
        name: 'name',
        weight: 6,
      },
      {
        name: 'listingSlug',
        weight: 5,
      },
      {
        name: 'productName',
        weight: 4,
      },
      {
        name: 'collections.name',
        weight: 3,
      },
      {
        name: 'collections.slug',
        weight: 2,
      },
      {
        name: 'productGroupName',
        weight: 1,
      },
    ],
  };

  const fuse = new Fuse<BasicProductFragment>(products, options);
  return fuse.search(normalizedQuery).map((match) => match.item);
}

/**
 * Returns the URL of an image based on the specified side.
 *
 * @param {Image[]} images - An array of image objects, each containing side and src properties.
 * @param {string} side - A string specifying the side of the image ('front' or 'back').
 * @returns {string} - The URL of the image on the specified side or an empty string if not found.
 */
export const getImageUrlBySide = (
  images:
    | readonly {
        readonly __typename?: 'AdditionalImage' | undefined;
        readonly side?: string | null | undefined;
        readonly src?: string | null | undefined;
      }[]
    | null
    | undefined,
  side: string
): string => {
  if (!images) return '';
  const imageUrl = images.find((image) => image?.side === side)?.src || '';
  return imageUrl;
};

/**
 * Return the additionalImages field of BasicProductFragment with the primary image in slot zero (if posible)
 *
 * @param product
 * @returns Array of AdditionalImageFragment, with the primary image in slot zero (if possble)
 */
export const buildSortedProductAdditionalImages = (
  product: BasicProductFragment
): ReadonlyArray<AdditionalImageFragment> => {
  const primaryImageUrl = product.imageUrl;

  const additionalImages = product.additionalImages || [];

  const primaryIndex = additionalImages.findIndex((image) => image.src === primaryImageUrl);

  // if the primary index was not found, or it's already the first item, just return the source
  if (!primaryIndex || primaryIndex <= 0) {
    return additionalImages;
  }

  // we need to move the primary image to the front of the images array
  const orderedImages = [...additionalImages];
  const [primaryImage] = orderedImages.splice(primaryIndex, 1);
  orderedImages.unshift(primaryImage);
  return orderedImages;
};

/**
 * Return the images field of ProductFragment with the primary image in slot zero (if possible)
 *
 * @param product
 * @returns Array of AdditionalImageFragment, with the primary image in slot zero (if possble)
 */
export const buildSortedPrimaryProductImages = (
  product: PrimaryProductFragment,
  primarySide?: string
): ReadonlyArray<ImageFragment> => {
  const images = product.images || [];

  const primaryIndex = primarySide ? images.findIndex((image) => image.label === primarySide) : -1;

  // if the primary index was not found, or it's already the first item, just return the source
  if (!primaryIndex || primaryIndex <= 0) {
    return images;
  }

  // we need to move the primary image to the front of the images array
  const orderedImages = [...images];
  const [primaryImage] = orderedImages.splice(primaryIndex, 1);
  orderedImages.unshift(primaryImage);
  return orderedImages;
};

/**
 * Build color options for product variants
 *
 * @param variants The array of colors/variants to convert
 * @returns Array of ColorOption
 */
export function buildColorOptions(variants?: readonly PrimaryProductFragment[]): ColorOption[] {
  const colorOptions: ColorOption[] = [];
  variants?.forEach((currentProduct?: PrimaryProductFragment) => {
    if (!currentProduct?.variationId || !currentProduct?.color || !currentProduct.attributes?.hex) {
      return;
    }

    colorOptions.push({
      id: currentProduct.variationId,
      name: currentProduct.color,
      colorCode: currentProduct.attributes?.hex,
    });
  });
  return colorOptions;
}

/**
 * Helper to convert from gql types to component size types
 *
 * @param sizes
 * @returns
 */
function buildSizeOptions(sizes?: readonly SizeFragment[]): SizeOption[] {
  const retVal: SizeOption[] = [];
  sizes?.forEach((s) => {
    if (s?.label && s?.id && s?.price) {
      retVal.push({
        label: s.label,
        id: s.id,
        price: s.price,
      });
    }
  });
  return retVal;
}

/**
 * Build size/available size options
 */
export const buildVariantSizeOptions = (variant?: PrimaryProductFragment): SizeOptions => {
  const sizes = buildSizeOptions(variant?.sizes || []);
  const availableSizes = buildSizeOptions(variant?.availableSizes || []);
  return { sizes, availableSizes };
};

/**
 * Determine if a product is digital based on size label
 * TODO: is this sane?
 *
 * @param size The size fragment for the item in question
 * @returns boolean
 */
export function isDigitalItem(size: SizeFragment): boolean {
  return size.label === DIGITAL_ITEM_SIZE_LABEL;
}

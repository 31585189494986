// these utils are primarily for src/constants/index.ts, so minimize dependencies as it
// needs to be importable in all runtimes (node, edge, ?)

/**
 * Convert an env var value to boolean ('1' or 'true' means true (case-insensitive))
 *
 * @param v The env var to be cast to boolean
 * @returns True if the the env var represents true, false otherwise
 */
export function envToBool(v?: string) {
  if (!v || typeof v !== 'string') {
    return false;
  }
  const lowerV = v.toLowerCase();
  return lowerV === 'true' || lowerV === '1';
}

/**
 * Helper to convert a potential string env var to an integer with default value if non-parsable input
 *
 * @param str  The env var as string (or null/undefined)
 * @param defaultValue the value to return if input can't be parsed as and integer
 * @returns Number, either parsed from str, or default
 */
export function envToInt(v: string | null | undefined, defaultValue: number): number {
  if (typeof v !== 'string' || !v) {
    return defaultValue;
  }
  const intVal = parseInt(v, 10);
  if (Number.isNaN(intVal)) {
    return defaultValue;
  }
  return intVal;
}

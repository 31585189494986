import { STORE_ASSET_PATH_TEMPLATE } from '@/constants';
// the most general of utilities

/**
 * Normalizes money from database
 *
 * @param value The value to normalize
 * @returns The normalized money value i.e. 7.99
 */
export const normalizeMoney = (value: number) => value / 100;

/**
 * Flattens money going to the database
 *
 * @param value The value to flatten
 * @returns The 'flattened' money value needed for stripe elements i.e. 799
 */
export const flattenMoney = (value: number) => Math.round(value * 100);

/**
 * Check if a value is nullish (null or undefined)
 *
 * @param value The value to check
 * @returns True if the value is null or undefined
 */
export function isNullish<T>(value: T | null | undefined): value is undefined | null {
  return value === null || value === undefined;
}

/**
 * Inverse of isNullish()
 *
 * @param value The value to check
 * @returns
 */
export function isNotNullish<T>(value: T | null | undefined): value is T {
  return !isNullish(value);
}

/**
 * Returns value if it is not nullish, defaultValue otherwise
 *
 * @param value
 * @param defaultValue
 * @returns
 */
export function notNullishOr<T, R>(value: T | null | undefined, defaultValue: R) {
  // could be return value ?? defaultValue, but use isNotNullish() for consistency
  return isNotNullish(value) ? value : defaultValue;
}

/**
 * Return first non-nullish (null/undefined) argument
 *
 * @param args Variable length collection of things that may be null, undefined or some other type
 * @returns The first argument that is not nullish, or undefined
 */
export function selectNonNullish<T>(...args: (T | undefined)[]): T | undefined {
  return args?.find((a) => !isNullish(a));
}

/**
 * Resolve the given URL to an absolute URL
 *
 * The function accepts a possibly undefined or null URL string and a store slug,
 * and returns a URL string that has been transformed to an absolute URL,
 * or undefined if the input URL was undefined or null.
 *
 * @param url - The input URL string which can be in various formats or undefined/null
 * @param storeSlug - The store slug to be used to build the asset path
 * @returns - The absolute URL as a string, or undefined
 */
export function normalizeAssetPathUrl(url: string, storeSlug: string): string {
  if (!url) return '';

  const getStoreAssetPath = (slug: string): string => {
    return STORE_ASSET_PATH_TEMPLATE.replace('{{storeSlug}}', slug || '');
  };

  let adjustedUrl = url;

  if (url.startsWith('//')) {
    // URL with protocol-relative scheme, prepend https:
    adjustedUrl = `https:${url}`;
  } else if (url.includes('{{assetPath}}')) {
    // Replace template string with the actual asset path
    adjustedUrl = url.replace('{{assetPath}}', getStoreAssetPath(storeSlug));
  }

  return adjustedUrl;
}

/**
 * Builds the store image URL with optional parameters like title, store logo, background color, and text color.
 *
 * This function uses the URL and URLSearchParams APIs to programmatically create the query string for the image URL.
 *
 * @param options Object containing the title, storeLogo, backgroundColor, and textColor for the image.
 * @returns A string representing the fully constructed URL for the store image.
 */
export function buildStoreImageUrl({
  title,
  storeLogo,
  backgroundColor,
  textColor,
}: {
  title: string;
  storeLogo?: string;
  backgroundColor?: string;
  textColor?: string;
}): string {
  const url = new URL('https://og-image.spri.ng/api/og');
  const params = url.searchParams;

  params.set('title', title);

  // Only set the parameters if they are defined
  if (textColor) {
    params.set('foreground', textColor);
  }

  if (backgroundColor) {
    params.set('color', backgroundColor);
  }

  if (storeLogo) {
    params.set('logo', storeLogo);
  }

  return url.toString();
}

// NOTE: process.env.NEXT_PUBLIC_ prefixed env vars are expanded at build time and are VISIBLE ON THE CLIENT, others are not
// process.env.NEXT_PUBLIC_ must be explicit (no vars, destructuring, etc) for build time replacement to work
// use caution so as not to leak data here

import { Currency } from '@/gql';
import { envToBool, envToInt } from '@/lib/env';
import { FulfillmentOption, FulfillmentRegion } from '@/types/preferences';

function assureEndsWith(s: string, c: string) {
  return s && s[s.length - 1] === c ? s : `${s}${c}`;
}

/**
 * Helper to turn a monolith url into it's corresponding api root
 * @param monolithUrl
 * @returns
 */
function buildDefaultApiBase(monolithUrl: string) {
  let retVal: string | undefined;
  try {
    // convert https://foo.com/ into https://api.foo.com/api/
    const url = new URL(monolithUrl);
    url.hostname = `api.${url.hostname}`;
    if (!url.pathname || url.pathname === '/') {
      url.pathname = '/api/';
    }
    retVal = url.toString();
  } catch {
    // punt and make api calls to the monolith
    retVal = `${assureEndsWith(monolithUrl, '/')}api/`;
  }

  // return, making sure it ends in a trailing slash
  return assureEndsWith(retVal, '/');
}

// URL for rails monolith
export const MONOLITH_URL =
  process.env.NEXT_PUBLIC_MONOLITH_URL ||
  (process.env.NODE_ENV === 'production' ? 'https://teespring.com/' : 'https://teedown.com/');

export const MONOLITH_API_BASE = process.env.NEXT_PUBLIC_MONOLITH_API_BASE || buildDefaultApiBase(MONOLITH_URL);

// graphql data source
export const GRAPHQL_SERVER: string = process.env.NEXT_PUBLIC_GRAPHQL_SERVER || 'https://graph.teespring.com/graphql';

// token for trackjs, if not defined trackjs will be disabled
// NOTE: ex
export const TRACKJS_TOKEN: string | undefined = process.env.NEXT_PUBLIC_TRACKJS_TOKEN || undefined; // "e0729fb7330843a1b736f34189024885";
export const TRACKJS_APPLICATION: string = process.env.NEXT_PUBLIC_TRACKJS_APPLICATION || 'storefront';
export const PAYPAL_CLIENT_ID =
  process.env.NEXT_PUBLIC_PAYPAL_CLIENT ||
  'AcGzi7TQznFhGKUVDrO19_DGtLskHA0HbIDY7StY5mip22mUn6UaOmoDQvzldmJXZao3ZyGjEkXHokBE';
export const STRIPE_KEY: string =
  process.env.NEXT_PUBLIC_STRIPE_KEY ||
  'pk_live_41stKibZRL6I2uiIwyFfgbpsnOamCqpQB2IPLQkk28fhppN1mJJdVDPnG9KJCIE909Mr2dIEK1aQ64CJDBCez8c8Q00fVMT8i5C';

// Was REACT_APP_PREVIEW_SECRET_KEY in the legacy store, not much of a secret (though not much need for it to be)
export const PREVIEW_KEY = process.env.NEXT_PUBLIC_PREVIEW_KEY || '7iELhdfFoLpLkhnuqBTZNtCr';

export const LAUNCHDARKLY_ID = process.env.NEXT_PUBLIC_LAUNCHDARKLY_ID ?? '644c17666332a912b6d44fdb';
export const AGE_VERIFY_REDIRECT = process.env.NEXT_PUBLIC_AGE_VERIFY_REDIRECT ?? 'https://spri.ng';

// search param keys
export const SEARCH_PARAM_PAGE = 'page';
export const SEARCH_PARAM_PRODUCT_ID = 'product';
export const SEARCH_PARAM_PID = 'pid'; // alias for SEARCH_PARAM_PRODUCT_ID
export const SEARCH_PARAM_VARIATION_ID: string = 'variation';
export const SEARCH_PARAM_SIZE_ID: string = 'size';

// special value for listing id product id when non-is specified (can't have an empty string as a path component)
export const LISTING_PAGE_PRODUCT_ID_DEFAULT = 'default';
// special value for the unlikely case where orgin isn't available in request
export const ORIGIN_DEFAULT = 'default';
// special value for the case where page isn't specified (or is invalid)
export const PAGEARG_DEFAULT = 'default';

export const PAYMENT_SERVICE_URL = process.env.NEXT_PUBLIC_PAYMENT_SERVICE_URL || `https://payments.spri.ng/`;

/**
 * Product page sizes
 */
// Products per page on the main products page
export const PRODUCT_PAGE_ITEMS = envToInt(process.env.NEXT_PUBLIC_PRODUCT_PAGE_ITEMS, 24);
// Products per page on the collection page when showing a whole collection
export const COLLECTION_PAGE_ITEMS = PRODUCT_PAGE_ITEMS;
// Products per sub collection preview collection page when showing a set of sub-collections
export const COLLECTION_PAGE_PREVIEW_ITEMS = 8;
/** Products per page when requesting all basic product data for a store */
export const ALL_STORE_BASIC_PRODUCTS_PAGE_ITEMS = 128;

export const GTM_ID: string = process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER || 'GTM-5LTK4NG';

/** Template for expanding `{assetPath}` macros in asset paths.  The template
 * can contain `{storeSlug}` macros, which will be converted to the slug for
 * active store */
export const STORE_ASSET_PATH_TEMPLATE =
  process.env.NEXT_PUBLIC_STORE_ASSET_PATH_TEMPLATE ||
  'https://premium-storefronts.s3.amazonaws.com/storefronts/{{storeSlug}}/assets';

/** Screen widths */
export const MEDIUM_SCREEN_SIZE = 768;
export const BIG_SCREEN_SIZE = 1200;
export const DESKTOP_SCREEN_SIZE = 1024;
export const DISTANCE_FROM_TOP = 100;
/**
 * Defaults
 */
export const DEFAULT_CURRENCY: Currency = 'USD';
export const DEFAULT_FULFILLMENT_OPTION: FulfillmentOption = 'USA';
export const DEFAULT_FULFILLMENT_REGION: FulfillmentRegion = 'USA';
/** What store to show if no slug is provided? */
export const DEFAULT_STORE_SLUG: string = process.env.NEXT_PUBLIC_DEFAULT_STORE_SLUG || 'spring-78';

/** Constants for the known theme flavors  */
export const HERO_BANNER_TEMPLATE = { BASIC: 5, ADVANCED: 2 };

/** Base url for legacy store build */
export const LEGACY_STORE_BASE_URL: string =
  process.env.NEXT_PUBLIC_LEGACY_STORE_BASE_URL || 'https://creator-spring-production-legacy.netlify.app';

/** Allowed domains for wildcard store id subdomain if no higher precedence store id tuning was found (env var, request header)
 * This can be trimmed per-env, but for now I'm making it globally tuned by default for backwards compatibility.  Envs, like prod,
 * can simplify the list, if so desired.
 *
 * <mystoreid>.<domain in list> will use store id mystoreid *
 * ex; foo.creator-spring.com will map to store foo
 *
 * allow localhost for development
 */
export const STOREID_ALLOWED_WILDCARD_DOMAINS =
  process.env.NEXT_STOREID_ALLOWED_WILDCARD_DOMAINS ||
  'creator-spring.com,preview.teespring.com,teespring-develop.com,preview.teespring-develop.com,teedown.com,preview.teedown.com,teespring.com,myteespring.co,localhost';

/**
 * Special case for the new preview url, which includes the branch.  Leaving
 * separate/hard coded from STOREID_ALLOWED_WILDCARD_DOMAINS for simplicity
 */
export const STOREID_PREVIEW_WILDCARD_DOMAINS_RE = /^([^.]+)\.[^.]+\.storefront\.staging\.spring$/;
/**
 * Allow preview mode requests?
 */
export const PREVIEW_MODE_ENABLED = envToBool(process.env.NEXT_PUBLIC_PREVIEW_MODE_ENABLED);

/**
 * Ignore whitelists/blacklist and always display storefront (mostly for preview builds)?
 */
export const FORCE_STOREFRONT = envToBool(process.env.NEXT_PUBLIC_FORCE_STOREFRONT);

/**
 * Header used to pass store id around
 */
export const HEADER_STORE_ID = 'x-spring-store-id';

/**
 * Header passed by Netlify as part of the custom domain proxy
 * containing the geo ip country code at the proxy request time
 */
export const HEADER_NETLIFY_PROXY_COUNTRY = 'x-country';

/**
 * Url (or relative path) to default favicon
 */
export const DEFAULT_FAVICON = '/favicon.png';

/**
 * Mexico Product Ids
 */
export const HACK_TEMP_MEXICO_PRODUCT_IDS = new Set([2, 14, 46, 76, 87, 117, 175, 212, 227, 328, 345]);

/**
 * Contant for the size label used to identify digital items.
 * TODO: is there a better way to identify a digital item?
 */
export const DIGITAL_ITEM_SIZE_LABEL = 'Digital';

/**
 * Key applied to all cached requests (and pages built from them) to allow for a big hammer clear
 */
export const CACHE_KEY_ALL = '#all';

/**
 * Key applied to all cached requests that include product pricing (must match value in rails)
 */
export const CACHE_KEY_PRODUCT_PRICING = 'product-pricing';

/**
 * Cache auto invalidation seconds (non-isr)
 */
export const NEXT_REVALIDATE_SECS = Math.max(envToInt(process.env.NEXT_REVALIDATE_SECS, 60), 0);

/**
 * Cache auto invalidation seconds (isr)
 */
export const NEXT_REVALIDATE_ISR_SECS = Math.max(envToInt(process.env.NEXT_REVALIDATE_ISR_SECS, 24 * 60 * 60), 0);

/**
 * Disable next image optimizations?
 */
export const NEXT_DISABLE_IMAGE_OPTIMIZATION = envToBool(process.env.NEXT_PUBLIC_DISABLE_IMAGE_OPTIMIZATION);

/**
 * Manual custom domain store id cache cookie max age (in seconds).
 */
export const CUSTOM_DOMAIN_STORE_ID_COOKIE_MAXAGE = Math.max(
  envToInt(process.env.NEXT_CUSTOM_DOMAIN_STORE_ID_COOKIE_MAXAGE, 600),
  0
);
